$theme-colors: (
  "primary": #653e36,
);
@import "~bootstrap/scss/bootstrap";

.Content {
    display: flex;
    flex-direction: column;
    overflow: auto;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 20px 0px;
}
.Page {
    text-align: center;
    display: flex;
    background-color: #f0f3f5;
    font-family: "Source Sans Pro", sans-serif;
    height: 100vh;
}
.Content h1 {
    font-size: calc(1.3rem + 1.6vw);
    font-weight: 700;
    color: rgb(49, 51, 63);
}
.Content h3 {
    font-weight: 600;
    color: rgb(49, 51, 63);
    letter-spacing: -0.005em;
    padding: 0.5rem 0px 1rem;
    margin: 0px;
    line-height: 1.2;
    font-size: calc(1.3rem + .6vw);
    text-align: left;
}
.Search {
    width: 100%;
    max-width: 46rem;
}
.Search-Button-Container {
    text-align: right;
}
.react-google-flight-datepicker .date:focus::after, .react-google-flight-datepicker .date.is-focus::after {
    border: 2px solid transparent!important;
}
.react-google-flight-datepicker .day.selected::after {
    background-color: #653e36!important;
}
.react-google-flight-datepicker .dialog-footer .submit-button {
    background-color: #653e36!important;
}
.react-google-flight-datepicker .icon-calendar {
    fill: #a76d60!important;
}
.react-google-flight-datepicker .day:hover::after {
    border: 2px solid #653e36!important;
}
.react-google-flight-datepicker .day.hovered {
    background-color: #ffd7ce!important;
}
.react-google-flight-datepicker .day.selected.hovered.end {
    background: linear-gradient(90deg, #ffd7ce 50%, #fff 50%)!important;
}

.react-google-flight-datepicker .day.hovered.end {
    background: linear-gradient(90deg, #ffd7ce 50%, #fff 50%) !important;
}

.react-google-flight-datepicker .day.selected.hovered {
    background: linear-gradient(90deg, #fff 50%, #ffd7ce 50%)!important;
}
.react-google-flight-datepicker .date-picker {
    background: transparent!important;
    padding: 0px!important;
}
.react-google-flight-datepicker .date.is-focus {
    background: #fff!important;
}
.form-control:focus {
    border-color: #a76d60;
    box-shadow: none;
    box-shadow: 0 0 0 0.25rem #a76d6040;
}
.form-select:focus {
    border-color: #a76d60;
    outline: 0;
    box-shadow: 0 0 0 0.25rem #a76d6040;
}
.form-label {
    font-size: 14px;
}
.Bottom-Row {
    text-align: left;
}

// .Search input {
//     background: rgb(240, 242, 246);
// }