.Sidebar-Container {
    width: 260px;
    display: flex;
    flex-direction: row-reverse;
    flex-shrink: 0;
}
.Sidebar {
    background: rgb(240, 242, 246);
    border-right: 1px solid #d5d5d5;
    text-align: left;
    padding: 10px 0px;
    display: flex;
    height: 100vh;
    width: 100%;
    box-sizing: border-box;
    flex-direction: column;
    align-items: center;
}

.Sidebar-open {
    position: relative;
    width: 100%;
    right: 0%;
}
.Sidebar-close {
    position: relative;
    right: 100%;
}
.Sidebar-Container-open {
    width: 260px;
}
.Sidebar-Container-close {
    flex: 0;
    width: 0%;
}

.Sidebar-Header {
    flex: 1;
    padding: 20pt 0pt;
    width: 90%;
}
.Sidebar-Header .Logo {
    width: 50px;
    height: fit-content;
    object-fit: contain;
    margin-right: 0.5rem;
}
.Logo img {
    height: 50px;
    position: relative;
    bottom: 14px;
}
.Sidebar-Header h3 {
    height: fit-content;

}
.Sidebar-Header .Close {
    width: 2rem;
    height: 2rem;
    object-fit: contain;
    margin-left: auto;
    cursor: pointer;
    background-color: transparent;
    border: none;
    border-radius: 50%;
}
.Sidebar-Header .Open {
    width: 2rem;
    height: 2rem;
    margin-left: -2rem;
    object-fit: contain;
    position: relative;
    right: -5.5rem;
    cursor: pointer;
    background-color: transparent;
    border: none;
    border-radius: 50%;
}
.Visible {
    visibility: visible;
}
.Hidden {
    visibility: hidden;
}
.Sidebar-Content {
    flex: 9;
    font-size: 16px;
    width: 90%
}
.Sidebar-Content p {
    padding: 0px 20px;
}
.Sidebar-Settings {
    flex: 2;
}
.Sidebar-Element {
    padding: 10px 20px;
    vertical-align: middle;
    cursor: pointer;
}
.Sidebar-Element svg {
    width: 1rem!important;
    height: 1rem!important;
    margin-right: 1rem!important;
    display: inline-block;
    vertical-align: middle;
}
.Sidebar-Element span {
    vertical-align: middle;
}
.active-sidebar {
    background: rgba(25, 83, 95, 0.2)!important;
    border-right: 2px solid #19535f;
    color: #19535f;
}
.Sidebar-Element:hover {
    background: rgba(25, 83, 95, 0.1);
}
.rounded-circle {
    border-radius: 50%!important;
}
.height-16 {
    height: 4rem!important;
}
.width-16 {
    width: 4rem!important;
}
.font-weight-medium {
    font-weight: 500!important;
    font-size: 1.5rem;
}
.Sidebar a {
    text-decoration: none!important;
    color: black!important;
}

.Sidebar-Element svg {
    color: #a76d60;
}